import React, { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { clearAllLoadingStates } from '../../reducers/loading'

const RouteChangeHandler: React.FC<{ children: React.ReactNode }> = ({
	children,
}) => {
	const location = useLocation()
	const dispatch = useDispatch()

	useEffect(() => {
		dispatch(clearAllLoadingStates())
	}, [location.pathname, dispatch])

	return <>{children}</>
}

export default RouteChangeHandler
