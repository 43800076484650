import {
	API,
	GoogleAdsVisualisationPayload,
	AdsGoogleCampaignItem,
	GoogleAdsGroupItem,
	GoogleAdItem,
	EAdsTypeOptions,
	FacebookAdEditingPayload,
	FaceBookAdCreativePayload,
	FaceBookAdCreativeEditingPayload,
	ROASAllogatorUpdatePayload,
	AdGroupBidModifier,
	ROASQuery,
	GoogleAdsChangeEvent,
	GoogleAdsAdGroupMetricsReportData,
} from '../constants'
import { replaceParamsUri } from '../helpers/apiHelpers'
import {
	CustomProductLabel,
	eCommerceProductFilterOptions,
	eCommerceProductLabelType,
	ESelectedToShow,
	IShopifyProductWithConversion,
} from '../reducers/ads'
import { ActionWithoutPayload, SignOut } from './audit'
import { CreateProject, GetProjectById } from './project'
import {
	GET_GOOGLE_ADS_CAMPAIGNS,
	GET_GOOGLE_ADS_AD_GROUPS_BY_CAMPAIGN,
	GET_GOOGLE_ADS_BY_AD_GROUP_ID,
	GET_GOOGLE_ADS_CAMPAIGNS_SUCCESS,
	GET_GOOGLE_ADS_AD_GROUPS_BY_CAMPAIGN_SUCCESS,
	GET_GOOGLE_ADS_BY_AD_GROUP_ID_SUCCESS,
	GET_GOOGLE_ADS_ALL_ADS_SUCCESS,
	GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS_SUCCESS,
	GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS,
	GET_GOOGLE_ADS_ALL_ADS,
	GOOGLE_ADS_CHANGE_FIELD,
	RESET_GOOGLE_ADS,
	RESET_GOOGLE_ADS_FILTERS,
	POST_CREATE_GOOGLE_ADS_CAMPAIGN,
	VALIDATE_GOOGLE_ADS_RESOURCE,
	SAVE_GOOGLE_ADS_RESOURCE,
	VALIDATE_EDIT_GOOGLE_KEYWORD,
	SAVE_EDIT_GOOGLE_KEYWORD,
	VALIDATE_EDIT_GOOGLE_AD,
	SAVE_EDIT_GOOGLE_AD,
	RESET_FACEBOOK_ADS,
	RESET_FACEBOOK_ADS_FILTERS,
	GET_FACEBOOK_ADS_CAMPAIGN_DATA_SUCCESS,
	GET_FACEBOOK_ADS_AD_SET_DATA_SUCCESS,
	GET_FACEBOOK_ADS_DATA_SUCCESS,
	GET_FACEBOOK_ADS_DATA,
	GET_FACEBOOK_ADS_AD_SET_DATA,
	GET_FACEBOOK_ADS_CAMPAIGN_DATA,
	FACEBOOK_ADS_CHANGE_FIELD,
	ADS_CHANGE_TO_SHOW,
	GET_GOOGLE_AD_GROUP_BY_AD_GROUP_ID,
	GET_GOOGLE_AD_GROUP_BY_AD_GROUP_ID_SUCCESS,
	RESET_SELECTED_GOOGLE_ADS,
	SET_SELECTED_AD_ITEM,
	GET_FACEBOOK_POSTS,
	EDIT_FACEBOOK_AD,
	GET_FACEBOOK_AD_CREATIVE,
	EDIT_FACEBOOK_AD_CREATIVE,
	GET_AD_GROUPS_BID_MODIFIER,
	UPDATE_ROAS_ALLOGATOR,
	GET_AD_GROUPS_BID_MODIFIER_SUCCESS,
	CHANGE_AD_GROUPS_BID_MODIFIER,
	RESET_ADS_STATE,
	GET_GOOGLE_ADS_CHANGE_EVENTS_SUCCESS,
	GET_GOOGLE_ADS_CHANGE_EVENTS,
	GET_GROUPED_ADS_CAMPAIGN_DATA_SUCCESS,
	GET_GROUPED_ADS_CAMPAIGN_DATA,
	GET_GOOGLE_ADS_CAMPAIGNS_ADS_CHANGE_EVENTS,
	GET_GOOGLE_ADS_CAMPAIGNS_ADS_CHANGE_EVENTS_SUCCESS,
	GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS_ADS_CHANGE_EVENTS,
	GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS_ADS_CHANGE_EVENTS_SUCCESS,
	GET_PRODUCT_LABEL,
	GET_PRODUCT_LABEL_FAIL,
	GET_PRODUCT_LABEL_SUCCESS,
	SAVE_PRODUCT_LABEL_SUCCESS,
	SAVE_PRODUCT_LABEL,
	DELETE_PRODUCT_LABEL_SUCCESS,
	DELETE_PRODUCT_LABEL,
	CHANGE_CUSTOM_PRODUCT_LABEL,
	CHANGE_CUSTOM_PRODUCT_LABEL_CRITERIA,
	GET_SHOPIFY_PRODUCT_FEED,
	GET_SHOPIFY_PRODUCT_FEED_SUCCESS,
	CREATE_SUPPLEMENTAL_FEED,
	GET_SUPPLEMENTAL_FEED_FILES,
	GET_SUPPLEMENTAL_FEED_FILES_SUCCESS,
	GET_SUPPLEMENTAL_FEED_FILE,
} from './types'

interface Action<T, P> {
	readonly type: T
	readonly payload: { data: P; status?: number }
}
export interface ActionChangeField<T, P> {
	readonly type: T
	readonly payload: P
}

export type GoogleAdsChangeFieldType = {
	field: 'property' | 'operator' | 'value'
	value: number
}

export type CampaignDataWithOptions = {
	[key: string]: GoogleAdsAdGroupMetricsReportData[]
} & {
	options?: Record<string, { name: string; resource_name: string }>
}

type AdsGoogleCampaignResponse = AdsGoogleCampaignItem[]

type GetGoogleAdsVisualisationCampaignsAction = Action<
	typeof GET_GOOGLE_ADS_CAMPAIGNS_SUCCESS,
	AdsGoogleCampaignResponse
>
type GetGoogleAdsChangeEventsCampaignsAction = Action<
	typeof GET_GOOGLE_ADS_CAMPAIGNS_ADS_CHANGE_EVENTS_SUCCESS,
	AdsGoogleCampaignResponse
>
export type GetGropedGoogleAdsCampaignsAction = Action<
	typeof GET_GROUPED_ADS_CAMPAIGN_DATA_SUCCESS,
	CampaignDataWithOptions
>
type GetGoogleAdsVisualisationAdGroupsAction = Action<
	typeof GET_GOOGLE_ADS_AD_GROUPS_BY_CAMPAIGN_SUCCESS,
	GoogleAdsGroupItem[]
>
type GetGoogleAdsVisualisationAdsAction = Action<
	typeof GET_GOOGLE_ADS_BY_AD_GROUP_ID_SUCCESS,
	GoogleAdItem[]
>
type GetGoogleAdsByAdgroupIdVisualisationAdsAction = Action<
	typeof GET_GOOGLE_AD_GROUP_BY_AD_GROUP_ID_SUCCESS,
	GoogleAdsGroupItem[]
>
type GetGoogleAdsVisualisationAllAdsAction = Action<
	typeof GET_GOOGLE_ADS_ALL_ADS_SUCCESS,
	GoogleAdItem[]
>
type GetGoogleAdsVisualisationAllAdGroupsAction = Action<
	typeof GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS_SUCCESS,
	GoogleAdsGroupItem[]
>
type GetGoogleAdsVisualisationAllAdGroupsAdsChangeEventsAction = Action<
	typeof GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS_ADS_CHANGE_EVENTS_SUCCESS,
	GoogleAdsGroupItem[]
>
type GetFacebookAdsVisualisationCampaignsAction = Action<
	typeof GET_FACEBOOK_ADS_CAMPAIGN_DATA_SUCCESS,
	[]
	// FacebookAdsCampaignResponse
>
type GetGoogleAdsEventsAction = Action<
	typeof GET_GOOGLE_ADS_CHANGE_EVENTS_SUCCESS,
	GoogleAdsChangeEvent[]
>
type GetFacebookAdsVisualisationAllAdSetsAction = Action<
	typeof GET_FACEBOOK_ADS_AD_SET_DATA_SUCCESS,
	// FacebookAdsGroupItem[]
	[]
>
// type GetFacebookAdsVisualisationAdsAction = Action<
// 	typeof GET_FA,
// 	GoogleAdItem[]
// >
type GetFacebookAdsVisualisationAllAdsAction = Action<
	typeof GET_FACEBOOK_ADS_DATA_SUCCESS,
	// FacebookAdItem[]
	[]
>
// type GetFacebookAdsVisualisationAllAdGroupsAction = Action<
// 	typeof GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS_SUCCESS,
// 	FacebookAdsGroupItem[]
// >
export type FacebookAdsChangesFieldAction = ActionChangeField<
	typeof FACEBOOK_ADS_CHANGE_FIELD,
	{ field: 'property' | 'operator' | 'value'; value: number }
>
type ResetFacebookAds = ActionWithoutPayload<typeof RESET_FACEBOOK_ADS>
type ResetFacebookAdsFilters = ActionWithoutPayload<
	typeof RESET_FACEBOOK_ADS_FILTERS
>
type ResetAdsState = ActionWithoutPayload<typeof RESET_ADS_STATE>
export type GoogleAdsChangesFieldAction = ActionChangeField<
	typeof GOOGLE_ADS_CHANGE_FIELD,
	{ field: 'property' | 'operator' | 'value'; value: number }
>
export type GoogleSetSelectedAdItemdAction = ActionChangeField<
	typeof SET_SELECTED_AD_ITEM,
	GoogleAdItem | GoogleAdsGroupItem
>

type CreateGoogleAdsCampaign = Action<
	typeof POST_CREATE_GOOGLE_ADS_CAMPAIGN,
	Record<string, any>
>

type ResetGoogleAds = ActionWithoutPayload<typeof RESET_GOOGLE_ADS>
type ResetSelectedGoogleAds = ActionWithoutPayload<
	typeof RESET_SELECTED_GOOGLE_ADS
>
type ResetGoogleAdsFilters = ActionWithoutPayload<
	typeof RESET_GOOGLE_ADS_FILTERS
>

export type AdsChangeSelectedToShow = ActionChangeField<
	typeof ADS_CHANGE_TO_SHOW,
	{ value: ESelectedToShow }
>

export type GetAdGroupsBidModifier = Action<
	typeof GET_AD_GROUPS_BID_MODIFIER_SUCCESS,
	AdGroupBidModifier[]
>

export type ChangeAdGroupsBidModifier = ActionChangeField<
	typeof CHANGE_AD_GROUPS_BID_MODIFIER,
	AdGroupBidModifier[]
>

type GetProductLabel = Action<
	typeof GET_PRODUCT_LABEL_SUCCESS,
	CustomProductLabel
>

type SaveProductLabel = Action<
	typeof SAVE_PRODUCT_LABEL_SUCCESS,
	eCommerceProductLabelType[]
>

type DeleteProductLabel = Action<
	typeof DELETE_PRODUCT_LABEL_SUCCESS,
	eCommerceProductLabelType[]
>

type ChangeCustomProductLabel = ActionChangeField<
	typeof CHANGE_CUSTOM_PRODUCT_LABEL,
	CustomProductLabel
>
type ChangeCustomProductLabelCriteria = ActionChangeField<
	typeof CHANGE_CUSTOM_PRODUCT_LABEL_CRITERIA,
	any
	// { name: keyof CustomProductLabel; data: eCommerceProductLabel }
>

type GetShopifyProductFeed = Action<
	typeof GET_SHOPIFY_PRODUCT_FEED_SUCCESS,
	{
		kind: string
		products: IShopifyProductWithConversion[]
	}
>

type GetSupplementalFeedFiles = Action<
	typeof GET_SUPPLEMENTAL_FEED_FILES_SUCCESS,
	{ name: string; createdDate: Date }[]
>

export type AdsVisualisationActions =
	| GetGoogleAdsVisualisationAdGroupsAction
	| GetGoogleAdsVisualisationAdsAction
	| GetGoogleAdsVisualisationCampaignsAction
	| GetGoogleAdsVisualisationAllAdsAction
	| GetGoogleAdsVisualisationAllAdGroupsAction
	| GoogleAdsChangesFieldAction
	| ResetGoogleAds
	| SignOut
	| ResetGoogleAdsFilters
	| GetFacebookAdsVisualisationCampaignsAction
	| GetFacebookAdsVisualisationAllAdSetsAction
	| GetFacebookAdsVisualisationAllAdsAction
	| FacebookAdsChangesFieldAction
	| ResetFacebookAds
	| ResetFacebookAdsFilters
	| AdsChangeSelectedToShow
	| GetGoogleAdsByAdgroupIdVisualisationAdsAction
	| ResetSelectedGoogleAds
	| GoogleSetSelectedAdItemdAction
	| GetProjectById
	| GetAdGroupsBidModifier
	| ChangeAdGroupsBidModifier
	| ResetAdsState
	| CreateProject
	| GetGoogleAdsEventsAction
	| GetGoogleAdsChangeEventsCampaignsAction
	| GetGoogleAdsVisualisationAllAdGroupsAdsChangeEventsAction
	| GetProductLabel
	| SaveProductLabel
	| DeleteProductLabel
	| ChangeCustomProductLabel
	| ChangeCustomProductLabelCriteria
	| GetShopifyProductFeed
	| GetSupplementalFeedFiles
// Google Ads actions

export const getGoogleAdsCampaigns = (
	projectId: number,
	data: GoogleAdsVisualisationPayload,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_GOOGLE_ADS_CAMPAIGNS,
	)
	return {
		type: GET_GOOGLE_ADS_CAMPAIGNS,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}
export const getGoogleAdsCampaignsAdsChangeEvents = (
	projectId: number,
	data: GoogleAdsVisualisationPayload,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_GOOGLE_ADS_CAMPAIGNS,
	)
	return {
		type: GET_GOOGLE_ADS_CAMPAIGNS_ADS_CHANGE_EVENTS,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}
export const getGoogleGroupedAdsCampaigns = (
	projectId: number,
	data: GoogleAdsVisualisationPayload,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'type'],
		[projectId, 'campaign'],
		API.GET_GROUPED_GOOGLE_ADS_CAMPAIGNS,
	)
	return {
		type: GET_GROUPED_ADS_CAMPAIGN_DATA,
		payload: {
			request: {
				method: 'GET',
				url: `${apiUri}?from=${data.fromDate}&to=${data.toDate}${
					data.campaignName ? `&campaignId=${data.campaignName}` : ''
				}${data.segment ? `&segment=${data.segment}` : ''}`,
			},
		},
	}
}
export const getGoogleBiddingStrategiesAndBudgetsData = (
	projectId: number,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_GOOGLE_ADS_AD_GROUPS_BY_CAMPAIGN,
	)
	return {
		type: GET_GOOGLE_ADS_AD_GROUPS_BY_CAMPAIGN,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const getGoogleAdGroupsByCampaignId = (
	projectId: number,
	data: GoogleAdsVisualisationPayload,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_GOOGLE_ADS_AD_GROUPS_BY_CAMPAIGN,
	)
	return {
		type: GET_GOOGLE_ADS_AD_GROUPS_BY_CAMPAIGN,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}
export const getGoogleAllAdGroupsAndAssetGroups = (
	projectId: number,
	data: GoogleAdsVisualisationPayload,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS,
	)
	return {
		type: GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}
export const getGoogleAllAdGroupsAndAssetGroupsAdsChangeEvents = (
	projectId: number,
	data: GoogleAdsVisualisationPayload,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS,
	)
	return {
		type: GET_GOOGLE_ADS_ALL_AD_GROUPS_AND_ASSET_GROUPS_ADS_CHANGE_EVENTS,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}

// export const getAdGroupKeywords = (
// 	projectId: number,
// 	adGroupId: number,
// ): any => {
// 	const apiUri = replaceParamsUri(
// 		['projectId', 'auditSummaryId'],
// 		[projectId, adGroupId],
// 		API.GET_PAGE_DETAILS_BY_AUDIT_ID,
// 	)
// 	return {
// 		type: GET_PAGE_DETAILS_BY_AUDIT_ID,
// 		payload: {
// 			request: {
// 				method: 'GET',
// 				url: apiUri,
// 			},
// 		},
// 	}
// }
export const getGoogleAdsGroupId = (
	projectId: number,
	data: GoogleAdsVisualisationPayload,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_GOOGLE_ADS_BY_AD_GROUP_ID,
	)
	return {
		type: GET_GOOGLE_ADS_BY_AD_GROUP_ID,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}
export const getGoogleAdGroupByGroupId = (
	projectId: number,
	data: GoogleAdsVisualisationPayload,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_GOOGLE_AD_GROUP_BY_AD_GROUP_ID,
	)
	return {
		type: GET_GOOGLE_AD_GROUP_BY_AD_GROUP_ID,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}
export const getGoogleAdsAllAds = (
	projectId: number,
	data: GoogleAdsVisualisationPayload,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_GOOGLE_ADS_ALL_ADS,
	)
	return {
		type: GET_GOOGLE_ADS_ALL_ADS,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}
export const createGoogleAdsCampaign = (
	projectId: number,
	type: EAdsTypeOptions,
	data: Record<string, any>,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'type'],
		[projectId, type],
		API.POST_CREATE_GOOGLE_ADS_CAMPAIGN,
	)
	return {
		type: POST_CREATE_GOOGLE_ADS_CAMPAIGN,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}

export const resetGoogleAdsState = (): any => {
	return {
		type: RESET_GOOGLE_ADS,
	}
}
export const resetGoogleAdsFilters = (): any => {
	return {
		type: RESET_GOOGLE_ADS_FILTERS,
	}
}
export const resetSelectedGoogleAdsFilters = (): any => {
	return {
		type: RESET_SELECTED_GOOGLE_ADS,
	}
}
export const resetAdsState = (): any => {
	return {
		type: RESET_ADS_STATE,
	}
}
export const changeGoogleAdsField = (payload: {
	field: 'property' | 'operator' | 'value'
	value: number
}): any => {
	return {
		type: GOOGLE_ADS_CHANGE_FIELD,
		payload,
	}
}
export const changeCustomProductLabel = (payload: CustomProductLabel): any => {
	return {
		type: CHANGE_CUSTOM_PRODUCT_LABEL,
		payload,
	}
}
export const changeCustomProductLabelCriteria = (payload: {
	name: keyof CustomProductLabel
	data: eCommerceProductLabelType
}): any => {
	return {
		type: CHANGE_CUSTOM_PRODUCT_LABEL_CRITERIA,
		payload,
	}
}

export const setSelectedAdItem = (
	payload: GoogleAdItem | GoogleAdsGroupItem | null,
): any => {
	return {
		type: SET_SELECTED_AD_ITEM,
		payload,
	}
}

export const validateGoogleKeywordEditing = (
	projectId: number,
	data: Record<string, any>,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.VALIDATE_EDIT_GOOGLE_KEYWORD,
	)
	return {
		type: VALIDATE_EDIT_GOOGLE_KEYWORD,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data,
			},
		},
	}
}

export const saveGoogleKeyword = (
	projectId: number,
	data: Record<string, any>,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.SAVE_EDIT_GOOGLE_KEYWORD,
	)
	return {
		type: SAVE_EDIT_GOOGLE_KEYWORD,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data,
			},
		},
	}
}
/**
Validates the google ad request body
**/
export const validateEditGoogleAd = (
	projectId: number,
	data: Record<string, any>,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.VALIDATE_EDIT_GOOGLE_AD,
	)
	return {
		type: VALIDATE_EDIT_GOOGLE_AD,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data,
			},
		},
	}
}
/**
Once the google ads edits have been validated call this to actually edit the ad
**/
export const saveEditGoogleAd = (
	projectId: number,
	data: Record<string, any>,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.VALIDATE_SAVE_AD,
	)
	return {
		type: SAVE_EDIT_GOOGLE_AD,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data,
			},
		},
	}
}

export const validateGoogleAdResource = (
	projectId: number,
	type: EAdsTypeOptions,
	data: Record<string, any>,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'type'],
		[projectId, type],
		API.VALIDATE_GOOGLE_ADS_RESOURCE,
	)
	return {
		type: VALIDATE_GOOGLE_ADS_RESOURCE,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data,
			},
		},
	}
}

export const saveGoogleAdResource = (
	projectId: number,
	type: EAdsTypeOptions,
	data: Record<string, any>,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'type'],
		[projectId, type],
		API.SAVE_GOOGLE_ADS_RESOURCE,
	)
	return {
		type: SAVE_GOOGLE_ADS_RESOURCE,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data,
			},
		},
	}
}

// Facebook Ads actions

export const getFacebookAdsCampaigns = (
	projectId: number,
	data: GoogleAdsVisualisationPayload,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_FACEBOOK_ADS_CAMPAIGNS,
	)
	return {
		type: GET_FACEBOOK_ADS_CAMPAIGN_DATA,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}

export const getFacebookAllAdSets = (
	projectId: number,
	data: GoogleAdsVisualisationPayload,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_FACEBOOK_ADS_ALL_AD_SETS,
	)
	return {
		type: GET_FACEBOOK_ADS_AD_SET_DATA,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}

export const getFacebookAdsAllAds = (
	projectId: number,
	data: GoogleAdsVisualisationPayload,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_FACEBOOK_ADS_ALL_ADS,
	)
	return {
		type: GET_FACEBOOK_ADS_DATA,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}

export const getFacebookPosts = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_FACEBOOK_POSTS,
	)
	return {
		type: GET_FACEBOOK_POSTS,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}

export const resetFacebookAdsState = (): any => {
	return {
		type: RESET_FACEBOOK_ADS,
	}
}
export const resetFacebookAdsFilters = (): any => {
	return {
		type: RESET_FACEBOOK_ADS_FILTERS,
	}
}
export const changeFacebookAdsField = (payload: {
	field: 'property' | 'operator' | 'value'
	value: number
}): any => {
	return {
		type: FACEBOOK_ADS_CHANGE_FIELD,
		payload,
	}
}
export const editFaceBookAds = (
	projectId: number,
	type: EAdsTypeOptions,
	data: FacebookAdEditingPayload,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'type'],
		[projectId, type],
		API.EDIT_FACEBOOK_AD,
	)
	return {
		type: EDIT_FACEBOOK_AD,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data,
			},
		},
	}
}
export const getFaceBookAdCreative = (
	projectId: number,
	data: FaceBookAdCreativePayload,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_FACEBOOK_AD_CREATIVE,
	)
	return {
		type: GET_FACEBOOK_AD_CREATIVE,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data,
			},
		},
	}
}
export const editFaceBookAdCreative = (
	projectId: number,
	data: FaceBookAdCreativeEditingPayload,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.EDIT_FACEBOOK_AD_CREATIVE,
	)
	return {
		type: EDIT_FACEBOOK_AD_CREATIVE,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data,
			},
		},
	}
}
export const changeToShowAds = (payload: { value: ESelectedToShow }): any => {
	return {
		type: ADS_CHANGE_TO_SHOW,
		payload,
	}
}
export const getAdGroupsBidModifier = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_AD_GROUPS_BID_MODIFIER,
	)
	return {
		type: GET_AD_GROUPS_BID_MODIFIER,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}
export const changeAdGroupsBidModifier = (
	bidModifiers: AdGroupBidModifier[],
): any => {
	return {
		type: CHANGE_AD_GROUPS_BID_MODIFIER,
		payload: bidModifiers,
	}
}
export const updateForRoasAllogator = (
	projectId: number,
	type: EAdsTypeOptions,
	queryObj: ROASQuery,
	data: ROASAllogatorUpdatePayload,
): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'type', 'query'],
		[projectId, type, new URLSearchParams(queryObj as Record<string, any>)],
		API.UPDATE_ROAS_ALLOGATOR,
	)
	return {
		type: UPDATE_ROAS_ALLOGATOR,
		payload: {
			request: {
				method: 'PATCH',
				url: apiUri,
				data,
			},
		},
	}
}
export const getGoogleAdsEvents = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_GOOGLE_ADS_EVENTS,
	)
	return {
		type: GET_GOOGLE_ADS_CHANGE_EVENTS,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}
export const getProductLabel = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_PRODUCT_LABEL,
	)
	return {
		type: GET_PRODUCT_LABEL,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}
export const getShopifyProductFeed = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_SHOPIFY_PRODUCT_FEED,
	)
	return {
		type: GET_SHOPIFY_PRODUCT_FEED,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}
export const createProductLabel = (
	name: string,
	filters: eCommerceProductFilterOptions[],
	projectId: number,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.SAVE_PRODUCT_LABEL,
	)
	return {
		type: SAVE_PRODUCT_LABEL,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: {
					name,
					filters,
				},
			},
		},
	}
}
export const deleteProductLabel = (projectId: number, id: number): any => {
	const apiUri = replaceParamsUri(
		['projectId', 'id'],
		[projectId, id],
		API.DELETE_PRODUCT_LABEL,
	)
	return {
		type: DELETE_PRODUCT_LABEL,
		payload: {
			request: {
				method: 'DELETE',
				url: apiUri,
			},
		},
	}
}
export const createSupplementalFeedFile = (
	fileName: string,
	productFeed: any[],
	projectId: number,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.CREATE_SUPPLEMENTAL_FEED,
	)
	return {
		type: CREATE_SUPPLEMENTAL_FEED,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: {
					fileName,
					productFeed,
				},
			},
		},
	}
}
export const getAllSupplementalFeedFiles = (projectId: number): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_SUPPLEMENTAL_FEED_FILES,
	)
	return {
		type: GET_SUPPLEMENTAL_FEED_FILES,
		payload: {
			request: {
				method: 'GET',
				url: apiUri,
			},
		},
	}
}
export const getSupplementalFeedFile = (
	projectId: number,
	fileName: string,
): any => {
	const apiUri = replaceParamsUri(
		['projectId'],
		[projectId],
		API.GET_SUPPLEMENTAL_FEED_FILE,
	)
	return {
		type: GET_SUPPLEMENTAL_FEED_FILE,
		payload: {
			request: {
				method: 'POST',
				url: apiUri,
				data: {
					fileName,
				},
			},
		},
	}
}
