/**
 *
 *
 * @author Janne Hellsten <janne@dashboa.com>
 *
 * @copyright AI Marketing Oy 2022
 */

import React, { useEffect, useRef } from 'react'
import { RouteComponentProps } from '@reach/router'
import { history } from '../../reducers'

type Props = RouteComponentProps & {
	children: JSX.Element | JSX.Element[]
}

const NewVersionChecker = ({ children }: Props): JSX.Element => {
	const isReloading = useRef(false)

	const buildVersion = document.querySelector(
		'[name=build-version]',
	) as Element & { content: string }

	const { location } = history

	useEffect(() => {
		if (
			isReloading.current ||
			process.env.NODE_ENV === 'development' ||
			!buildVersion?.content
		) {
			return
		}

		try {
			const currentVersion = localStorage.getItem('buildVersion')

			if (!currentVersion) {
				localStorage.setItem('buildVersion', buildVersion.content)
			} else if (currentVersion !== buildVersion.content) {
				isReloading.current = true

				localStorage.setItem('buildVersion', buildVersion.content)

				setTimeout(() => {
					window.location.reload()
				}, 100)
			}
		} catch (error) {
			console.error('Version check failed:', error)
		}
	}, [buildVersion, location])

	return <>{children}</>
}

export default NewVersionChecker
