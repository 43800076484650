import { initializeApp } from 'firebase/app'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import {
	onSnapshot,
	getFirestore,
	collection,
	query,
	where,
	runTransaction,
	getDocs,
	Unsubscribe,
} from 'firebase/firestore'

import { INotification, setNotifications } from '../actions/message'
import { Dispatch } from 'redux'
import { getUserFirebaseToken } from '../actions/user'

const firebaseConfig = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
}

const notificationsCollection = process.env
	.REACT_APP_NOTIFICATIONS_COLLECTION as string

const app = initializeApp(firebaseConfig)
const db = getFirestore(app)

export const getNotifications = async (
	firebaseToken: string,
	dispatch: Dispatch,
): Promise<Unsubscribe | undefined> => {
	try {
		const auth = getAuth()
		console.log('firebaseToken', firebaseToken)
		console.log('auth', auth)
		const { user } = await signInWithCustomToken(auth, firebaseToken)
		console.log('user', user)
		const q = query(
			collection(db, notificationsCollection),
			where('uid', '==', user.uid),
		)
		const unsubscribe = onSnapshot(
			q,
			{ includeMetadataChanges: true },
			snapshot => {
				const [updatedNotification] = snapshot.docs
				if (updatedNotification && updatedNotification.data()) {
					dispatch(setNotifications(updatedNotification.data().notifications))
				}
			},
		)
		return unsubscribe
	} catch (error) {
		console.error('err', error)
		dispatch(getUserFirebaseToken())
	}
}
export const updateNotification = async (
	firebaseToken: string,
	updatedNotifications: INotification[],
	dispatch: Dispatch,
): Promise<void> => {
	try {
		const auth = getAuth()
		const { user } = await signInWithCustomToken(auth, firebaseToken)
		await runTransaction(db, async transaction => {
			const q = query(
				collection(db, notificationsCollection),
				where('uid', '==', user.uid),
			)
			const documents = await getDocs(q)
			const [notificationRef] = documents.docs
			if (!notificationRef.exists()) {
				throw Error('Document does not exist!')
			}

			await transaction.update(notificationRef.ref, {
				notifications: updatedNotifications,
			})
		})
	} catch (error) {
		console.error(error)
		dispatch(getUserFirebaseToken())
		throw error
	}
}
