/**
 * References on styling react-select :
 * https://react-select.com/styles#style-object
 * https://stackoverflow.com/questions/45291508/how-to-style-react-select-options
 * https://stackoverflow.com/questions/53657560/react-select-cannot-change-color-of-text-in-placeholder-within-text-box
 *
 * @author Jan Hellsten <hellsten@live.com>
 *
 * @copyright AI Marketing Oy 2021
 */

import React, { CSSProperties } from 'react'
import { useTranslation } from 'react-i18next'

import Select from 'react-select'

const colourStyles = {
	control: (defaultStyles: any) => ({
		...defaultStyles,

		width: '100%',

		height: '4rem',

		backgroundColor: '#182550',

		maxWidth: '300px',

		color: 'white',
		border: '0.1rem solid #3A4469',

		fontSize: '1.4rem',

		cursor: 'pointer',
	}),

	dropdownIndicator: (defaultStyles: any) => {
		return {
			...defaultStyles,
			fontSize: '1.4rem',
			color: 'white',
			marginRight: '0.8rem',
		}
	},

	placeholder: (defaultStyles: any) => {
		return {
			...defaultStyles,

			color: 'white',
		}
	},

	indicatorSeparator: (defaultStyles: any) => {
		return {
			...defaultStyles,

			display: 'none',
		}
	},

	input: (defaultStyles: any) => {
		return {
			...defaultStyles,

			// marginLeft: '2.3rem',

			cursor: 'pointer',

			color: 'white',

			'&:focus': {
				boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.3)',
				backgroundColor: 'white',
			},
			input: {
				color: 'white !important',
				fontWeight: 300,
			},
		}
	},

	menu: (defaultStyles: any) => {
		return {
			...defaultStyles,
			width: '90%',

			maxWidth: '300px',
		}
	},

	menuList: (defaultStyles: any) => {
		return {
			...defaultStyles,

			// marginLeft: '2.4rem',

			fontSize: '1.4rem',

			maxWidth: '300px',
		}
	},

	option: (defaultStyles: any) => {
		return { ...defaultStyles, cursor: 'pointer' }
	},

	// noOptionsMessage: defaultStyles => {

	// 	return { ...defaultStyles, padding: '2rem',text: 'No no no' }

	// },

	singleValue: (defaultStyles: any) => {
		return {
			...defaultStyles,

			width: '90%',
			top: '50%',
			marginLeft: '6px',
			maxWidth: '300px',
			fontSize: '1.4rem',
			lineHeight: '33px',
			color: 'white',
			backgroundColor: '#182550',
			fontWeight: 300,
		}
	},
	valueContainer: (defaultStyles: any) => {
		return {
			...defaultStyles,

			color: 'white',
		}
	},
	menuPortal: (base: any) => ({ ...base, zIndex: 99999 }),
}

const defaultColourStyles = {
	control: (defaultStyles: any) => ({
		...defaultStyles,

		width: '90%',

		// height: '4rem',

		//  backgroundColor: '#f5f5f5',

		maxWidth: '300px',

		border: '0.1rem solid #cecece',

		fontSize: '1.4rem',

		cursor: 'pointer',

		// borderRadius: '1rem'
	}),

	dropdownIndicator: (defaultStyles: any) => {
		return {
			...defaultStyles,
			fontSize: '1.4rem',
			color: 'black',
			marginRight: '0.8rem',
		}
	},

	placeholder: (defaultStyles: any) => {
		return {
			...defaultStyles,
			fontSize: '1.2rem',
			color: '#00034B',
		}
	},

	indicatorSeparator: (defaultStyles: any) => {
		return {
			...defaultStyles,

			display: 'none',
		}
	},

	input: (defaultStyles: any) => {
		return {
			...defaultStyles,

			// marginLeft: '2.3rem',

			cursor: 'pointer',

			'&:focus': {
				boxShadow: '0px 2px 15px rgba(0, 0, 0, 0.3)',
			},
		}
	},

	menu: (defaultStyles: any) => {
		return {
			...defaultStyles,
			width: '90%',

			maxWidth: '300px',
		}
	},

	menuList: (defaultStyles: any) => {
		return {
			...defaultStyles,

			// marginLeft: '2.4rem',

			fontSize: '1.4rem',
			zIndex: 99999,
			maxWidth: '300px',
		}
	},

	option: (defaultStyles: any) => {
		return { ...defaultStyles, cursor: 'pointer' }
	},

	// noOptionsMessage: defaultStyles => {

	// 	return { ...defaultStyles, padding: '2rem',text: 'No no no' }

	// },

	singleValue: (defaultStyles: any) => {
		return {
			...defaultStyles,

			width: '90%',
			top: '50%',
			marginLeft: '6px',
			maxWidth: '300px',
			lineHeight: '33px',
			fontSize: '1.4rem',
			color: '#00034B',
		}
	},
	multiValue: (defaultStyles: any, { isDisabled }: { isDisabled: boolean }) => {
		return {
			...defaultStyles,

			padding: '0.25rem',
			borderRadious: '20px',
			marginLeft: '6px',
			maxWidth: '300px',
			lineHeight: '33px',
			fontSize: '1.4rem',
			backgroundColor: isDisabled ? 'grey' : '#151f43',
		}
	},
	multiValueLabel: (defaultStyles: any) => {
		return { ...defaultStyles, color: 'white' }
	},
	multiValueRemove: (defaultStyles: any) => {
		return { ...defaultStyles, color: 'white' }
	},
	menuPortal: (base: any) => ({ ...base, zIndex: 99999 }),
}

const groupStyles = {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-between',
}

const groupLabelStyles: CSSProperties = { color: 'grey', textTransform: 'none' }

const groupBadgeStyles: CSSProperties = {
	backgroundColor: '#EBECF0',
	borderRadius: '2em',
	color: '#172B4D',
	display: 'inline-block',
	fontSize: 12,
	fontWeight: 'normal',
	lineHeight: '1',
	minWidth: 1,
	padding: '0.16666666666667em 0.5em',
	textAlign: 'center',
}

const formatGroupLabel = (data: any) => (
	<div style={groupStyles}>
		<span style={groupLabelStyles}>{data.label}</span>
		<span style={groupBadgeStyles}>{data.options.length}</span>
	</div>
)

const SelectWithSearch = (props: Record<any, any>): JSX.Element => {
	const { t } = useTranslation()
	if (props.fullWidth) {
		defaultColourStyles.control = (defaultStyles: any) => ({
			...defaultStyles,

			width: '100%',

			height: '4rem',

			//maxWidth: '300px',

			border: '0.1rem solid #cecece',

			fontSize: '1.4rem',

			cursor: 'pointer',
		})
	}
	if (props.customWidth) {
		defaultColourStyles.control = (defaultStyles: any) => ({
			...defaultStyles,
			width: props.customWidth,
			minWidth: props.customWidth,
			height: '4rem',
			maxWidth: '300px',
			border: '0.1rem solid #CECECE',
			fontSize: '1.4rem',
			cursor: 'pointer',
		})
	}
	if (props.isMulti) {
		defaultColourStyles.control = (defaultStyles: any) => ({
			...defaultStyles,
			width: props.fullWidth ? '100%' : '90%',
			maxWidth: props.fullWidth ? '100%' : '90%',
			boxShadow:
				'7px -7px 10px -5px rgb(59 94 218 / 7%), -5px 2px 9px 3px rgb(59 94 218 / 5%)',
			fontSize: '1.4rem',
			cursor: 'pointer',
			borderStyle: 'none',
			borderWidth: 'none',
		})
	}
	// if (props.isDisabled) {
	// 	defaultColourStyles.multiValue = (defaultStyles: any) => ({
	// 		...defaultStyles,
	// 		backgroundColor: 'grey',
	// 		pointerEvent: 'none',
	// 	})
	// }

	return (
		<Select
			{...props}
			placeholder={props.placeholder ? props.placeholder : t('common.select')}
			styles={props.isDark ? colourStyles : defaultColourStyles}
			formatGroupLabel={formatGroupLabel}
			isSearchable={props.disableSearch ? false : true}
			menuPortalTarget={document.body}
			isDisabled={props.isDisabled}
		/>
	)
}

export default SelectWithSearch
