import _ from 'lodash'
import React, { memo, useEffect } from 'react'
import { connect } from 'react-redux'
import LoadingBars from '../../components/UI/LoadingBars'
import { primaryColor } from '../../constants'
import {
	dashboardSelectors,
	DashboaReducerState,
	loadingSelectors,
} from '../../reducers'

type Props = {
	authLoading: boolean
	appLoading: boolean
	dashboardLoading: boolean
	children: JSX.Element
}

const LoadingScreen = ({
	authLoading,
	appLoading,
	dashboardLoading,
	children,
}: Props) => {
	const loading = authLoading || appLoading || dashboardLoading
	const body = document.querySelector('body')

	useEffect(() => {
		if (body) {
			body.style.overflow = loading ? 'hidden' : 'auto'
		}

		return () => {
			if (body) {
				body.style.overflow = 'auto'
			}
		}
	}, [loading, body])

	return (
		<>
			{loading && (
				<div className='loading-screen'>
					<LoadingBars color={primaryColor} />
				</div>
			)}
			{children}
		</>
	)
}
export default memo(
	connect((state: DashboaReducerState) => ({
		authLoading: loadingSelectors.getAuthLoading(state.loading),
		appLoading: loadingSelectors.getAppLoading(state.loading),
		dashboardLoading: dashboardSelectors.getLoading(state.dashboard),
	}))(LoadingScreen),
	(prevProps, nextProps) => _.isEqual(nextProps, prevProps),
)
