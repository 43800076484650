/**
 *
 * @param {integer | string} number
 * @returns Number with 1 decimal
 */

import _, { orderBy } from 'lodash'
import moment from 'moment'
import {
	AdsGoogleCampaignItem,
	CRO_GRAPH_VALUES,
	ESuggestionType,
	ETaskAttribute,
	ETaskType,
	FACEBOOK_GRAPH_VALUES,
	GoogleAdsGroupItem,
	GOOGLE_ADS_ACCOUNT_GRAPH_VALUES,
	GOOGLE_ADS_AD_GRAPH_VALUES,
	GOOGLE_ADS_CAMPAIGN_GRAPH_VALUES,
	GOOGLE_ADS_GRAPH_VALUES,
	IDataSource,
	NON_DATASOURCE_SEO_GRAPH_VALUES,
	ReportGoogleAnalyticsData,
	ReportNumbersConversions,
	SEO_GRAPH_VALUES,
	MetricsType,
	MetricsKeysType,
	ReportTemplateCustom,
	ECustomReportSegments,
	GoogleAdsScale,
	microsKeys,
} from '../constants'
import i18n, { formatAmountsIntl } from '../services/i18n'
import { fromMicrons, getTotalSum } from './dataTransformer'
import { EDatasourceType } from '../constants'
import { checkDataSource, formatDecimals } from './fnHelpers'
import { remark } from 'remark'
import remarkGfm from 'remark-gfm'
import remarkHtml from 'remark-html'
import { IShopifyProductWithConversion } from '../reducers/ads'

export const formatToFixedOne = (number: number): number | string => {
	if (!isNaN(number)) {
		return Number(number).toFixed(1)
	} else {
		return 0
	}
}

export const toHoursAndMinutes = (totalMinutes: number): string => {
	const hours = Math.floor(totalMinutes / 60)
	const minutes = Math.ceil((totalMinutes % 60) / 5) * 5

	return hours > 0
		? `${hours}h${minutes > 0 ? ` ${minutes}m` : ''}`
		: `${minutes}m`
}

/**
 *
 * @param {Array} thisMonthData Array of objects current month data with conversionRate property
 * @param {Array} lastMonthData Array of objects previous month data with conversionRate property
 * @returns String for conversion rate for site
 */

export const conversionRateValueFromData = (
	thisMonthData: ReportNumbersConversions,
	lastMonthData: ReportNumbersConversions,
): any => {
	const calcPercentage = (
		currentMonth: number,
		comparisonMonth: number,
	): number => {
		const decrease = currentMonth - comparisonMonth
		return Math.abs(Number(((decrease / currentMonth) * 100).toFixed(2)))
	}
	const percent = calcPercentage(
		thisMonthData.conversionRate,
		lastMonthData.conversionRate,
	)
	if (thisMonthData.conversionRate > lastMonthData.conversionRate) {
		return {
			conversionRate: formatAmountsIntl(
				formatToFixedOne(thisMonthData.conversionRate),
			),
			percent: `+${formatAmountsIntl(percent)}%`,
			barPercent: percent && percent > 100 ? 100 : percent,
		}
	} else if (thisMonthData.conversionRate === lastMonthData.conversionRate) {
		return {
			conversionRate: formatAmountsIntl(
				formatToFixedOne(thisMonthData.conversionRate),
			),
			percent: '+0%',
			barPercent: 0,
		}
	} else {
		return {
			conversionRate: formatAmountsIntl(
				formatToFixedOne(thisMonthData.conversionRate),
			),
			percent: `-${formatAmountsIntl(percent)}%`,
			barPercent: percent,
		}
	}
}

/**
 *
 * @param {Array} data Array of objects that needs to be reformatted
 * @param {string} value Value that should be used when combining data and will return data with that value
 * @param {boolean} checkDuplicates If need to combine values with same dates
 * @returns Array of objects with date and defined value
 */
export const formatGoogleAnalyticsData = (
	data: ReportGoogleAnalyticsData[],
	value: string,
	checkDuplicates = false,
	newKey?: string,
): any[] => {
	const key = newKey ? newKey : value
	const mapped = data.map((e: any) => {
		return {
			date: moment(e.date).format('DD.MM'),
			[key]: Number(e[value]),
		}
	})
	if (checkDuplicates) {
		const combined = mapped.reduce((accumulator: any[], cur) => {
			const date = cur.date
			const found = accumulator.find(elem => elem.date === date)
			if (found) {
				found[key] += cur[key]
			} else {
				accumulator.push(cur)
			}
			return accumulator
		}, [])
		return combined
	} else {
		return mapped
	}
}

export const formatCustomReportChartData = (
	compareData: Record<string, any>[],
	currentData: Record<string, any>[],
): any[] => {
	if (!currentData || (Array.isArray(currentData) && !currentData.length)) {
		return []
	}
	const keys: MetricsType | string[] = Object.keys(currentData[0])
	if (!compareData || (compareData && !compareData.length)) {
		const formattedCurrentData = currentData.map(data => {
			const formattedData = { ...data }
			keys.forEach(key => {
				if (key !== 'date' && !isNaN(Number(data[key as string]))) {
					formattedData[key] = Number(data[key as string])
				}
			})
			return formattedData
		})
		return formattedCurrentData
	}

	const dateRangeString = `(${moment(compareData[0].date).format(
		'YYYY-MM-DD',
	)} - ${moment(compareData[compareData.length - 1].date).format(
		'YYYY-MM-DD',
	)})`
	const combinedData = currentData.reduce(
		(combined: Record<string, any>[], curr, index): Record<string, any>[] => {
			const newObject = { ...curr }
			if (compareData[index]) {
				keys.forEach(key => {
					if (compareData[index][key as string] != null) {
						if (key != 'date' && !isNaN(Number(newObject[key as string]))) {
							newObject[key] = Number(curr[key as string])
							newObject[`${key}Compared`] = Number(
								compareData[index][key as string],
							)
						} else {
							newObject[`${key}Compared`] = compareData[index][key as string]
						}
					}
				})
			}
			newObject.comparisonDateRange = dateRangeString
			combined.push(newObject)
			return combined
		},
		[],
	)
	return combinedData
}

/**
 *
 * @param {Array} thisMonthData Array of objects current month data with users property
 * @param {Array} lastMonthData Array of objects previous month data with users property
 * @returns String for traffic for site
 */

export const trafficStringFromData = (
	thisMonthData: ReportGoogleAnalyticsData[],
	lastMonthData: ReportGoogleAnalyticsData[],
	lastYearData: ReportGoogleAnalyticsData[],
): string => {
	const calcPercetage = (currentMonth: number, comparisonMonth: number) => {
		const decrease = currentMonth - comparisonMonth
		return Number((decrease / currentMonth) * 100).toFixed(2)
	}
	const thisMonthTotal = getTotalSum(thisMonthData, 'users')
	const lastMonthTotal = getTotalSum(lastMonthData, 'users')
	const lastYearTotal = lastYearData.length
		? getTotalSum(lastYearData, 'users')
		: 0

	let str = ''
	if (lastYearTotal) {
		if (lastYearTotal > thisMonthTotal) {
			str += `${i18n.t(
				'report.timeperiods.overall traffic decreased compared to last',
				{ period: i18n.t('common.year') },
			)} ${calcPercetage(lastYearTotal, thisMonthTotal)}%.`
		} else if (lastYearTotal === thisMonthTotal) {
			str += `${i18n.t(
				'report.timeperiods.overall traffic stayed the same compared to last',
				{ period: i18n.t('common.year') },
			)}`
		} else if (lastYearTotal < thisMonthTotal) {
			str += `${i18n.t(
				`${i18n.t(
					'report.timeperiods.overall traffic increased compared to last',
					{ period: i18n.t('common.year') },
				)}`,
			)} ${calcPercetage(lastYearTotal, thisMonthTotal)}%.`
		}
	}

	if (thisMonthTotal > lastMonthTotal) {
		str += `\n${i18n.t(
			'report.timeperiods.overall traffic increased compared to last',
			{ period: i18n.t('common.month') },
		)} ${calcPercetage(thisMonthTotal, lastMonthTotal)}%`
	} else if (thisMonthTotal === lastMonthTotal) {
		str += `\n${i18n.t(
			'report.timeperiods.overall traffic stayed the same compared to last',
			{ period: i18n.t('common.month') },
		)} `
	} else if (thisMonthTotal < lastMonthTotal) {
		str += `\n${i18n.t(
			'report.timeperiods.overall traffic decreased compared to last',
			{ period: i18n.t('common.month') },
		)} ${calcPercetage(lastMonthTotal, thisMonthTotal)}%`
	}
	return str
}

export const extractContentHeadlines = (
	headlineText = '',
	keyword: string,
): string[] => {
	const headlines = []
	const formattedString = ' '.concat(
		headlineText
			.replace(/(?:\r\n|\r|\n)/g, ' ')
			.replaceAll(`${keyword}: ${keyword}:`, `${keyword}:`),
	)
	for (let i = 1; i < 8; i++) {
		const wordToCheck = ` ${i}. `
		const nextWordToCheck = ` ${i + 1}. `
		const startIndex = formattedString.indexOf(wordToCheck)
		const nextIndex = formattedString.indexOf(nextWordToCheck)
		if (startIndex !== -1) {
			if (
				nextIndex === -1 &&
				formattedString.substring(startIndex + 4).length !==
					formattedString.length
			) {
				headlines.push(formattedString.substring(startIndex + 4))
			} else {
				if (
					formattedString.substring(startIndex + 4, nextIndex - 1).length !==
					formattedString.length
				) {
					headlines.push(formattedString.substring(startIndex + 4, nextIndex))
				}
			}
		}
		if (!headlines.length) {
			headlines.push(formattedString)
		}
	}
	return headlines
}

export const getIncreasePercentage = (a: number, b: number): number => {
	return ((a - b) / b) * 100
}

const addROASSuggestionToBetterPerformingCampaign = (
	campaigns: AdsGoogleCampaignItem[],
	allocateBudget: number,
): AdsGoogleCampaignItem[] => {
	let allocateSet: { value: number }[] = []
	let sortValue: '' | 'ROAS' | 'pricePerConversion' = ''
	switch (campaigns.length) {
		case 1:
			allocateSet = [{ value: 100 }]
			break
		case 2:
			allocateSet = [{ value: 60 }, { value: 40 }]
			break
		case 3:
			allocateSet = [{ value: 50 }, { value: 30 }, { value: 20 }]
			break
		default:
			allocateSet = [{ value: 50 }, { value: 30 }, { value: 10 }, { value: 10 }]
			break
	}
	if (campaigns.every((campaign: AdsGoogleCampaignItem) => campaign.ROAS)) {
		sortValue = 'ROAS'
	} else if (
		campaigns.every(
			(campaign: AdsGoogleCampaignItem) => campaign.pricePerConversion,
		)
	) {
		sortValue = 'pricePerConversion'
	}
	const sortedCampaigns = _.orderBy(
		campaigns,
		sortValue,
		sortValue === 'ROAS' ? 'desc' : 'asc',
	)
	sortedCampaigns.map(
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-ignore Weird undebuggable build error
		(campaign: AdsGoogleCampaignItem, index): AdsGoogleCampaignItem => {
			if (index <= 4) {
				const increaseAmount = Number(
					(allocateBudget * allocateSet[index].value) / 100,
				)
				campaign.allogatorSuggestion =
					Number(fromMicrons(campaign.campaignBudget)) + increaseAmount
				return campaign
			} else {
				campaign.allogatorSuggestion = Number(
					fromMicrons(campaign.campaignBudget),
				)
				return campaign
			}
		},
	)
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore Weird undebuggable build error
	return sortedCampaigns
}

const addROASSuggestionToAdGroup = (
	adGroup: GoogleAdsGroupItem,
	averageCostPerConversion: number,
	averageROAS: number,
) => {
	const value = Object.assign({}, adGroup)
	if (
		!adGroup.ROAS &&
		adGroup.conversions &&
		adGroup.pricePerConversion &&
		adGroup.toBeCalculatedForROASAllogator
	) {
		const percentage = getIncreasePercentage(
			adGroup.pricePerConversion,
			Number(averageCostPerConversion),
		)
		if (percentage > 0 && percentage > 10) {
			value.allogatorSuggestion = -0.1
		} else if (percentage < 0 && percentage < -10) {
			value.allogatorSuggestion = 0.1
		} else {
			value.allogatorSuggestion = 0
			value.selectedForUpdate = false
		}
	} else if (
		adGroup.ROAS &&
		adGroup.conversions &&
		adGroup.toBeCalculatedForROASAllogator
	) {
		const percentage = getIncreasePercentage(adGroup.ROAS, Number(averageROAS))
		if (percentage > 0 && percentage > 10) {
			value.allogatorSuggestion = 0.1
		} else if (percentage < 0 && percentage < -10) {
			value.allogatorSuggestion = -0.1
		} else {
			value.allogatorSuggestion = 0
			value.selectedForUpdate = false
		}
	} else {
		value.allogatorSuggestion = 0
		value.selectedForUpdate = false
	}
	return value
}

const calculateAverageForROASSuggestion = <T>(
	array: T[],
	field: keyof T,
): string => {
	let totalGroupsWithData = 0
	array.map((campaign: any) => {
		if (campaign.conversions > 0) {
			totalGroupsWithData += 1
		}
	})
	return (
		(array.reduce((total, current) => {
			//@ts-expect-error {Operator '+=' cannot be applied to types 'number' and 'number | T[keyof T]'}
			total += !isNaN(current[field]) ? current[field] : 0
			return total
		}, 0) as unknown as number) / totalGroupsWithData || 0
	).toFixed(2)
}

export const sortCampaignBasedOnPerformance = (
	campaigns: AdsGoogleCampaignItem[],
	averageCostPerConversion: number,
	averageROAS: number,
): any => {
	const betterPerformanceCampaigns: AdsGoogleCampaignItem[] = []
	const worsePerformanceCampaigns: AdsGoogleCampaignItem[] = []
	const averageCampaigns: AdsGoogleCampaignItem[] = []
	let deductedBudget = 0
	campaigns.map((campaign: AdsGoogleCampaignItem) => {
		if (!campaign.ROAS && campaign.conversions && campaign.pricePerConversion) {
			const percentage = getIncreasePercentage(
				campaign.pricePerConversion,
				Number(averageCostPerConversion),
			)
			if (
				percentage > 0 &&
				percentage > 10 &&
				campaign.toBeCalculatedForROASAllogator
			) {
				campaign.allogatorSuggestion = Number(
					(fromMicrons(campaign.campaignBudget) * 0.9).toFixed(2),
				)
				deductedBudget += Number(fromMicrons(campaign.campaignBudget) * 0.1)
				worsePerformanceCampaigns.push(campaign)
			} else if (
				percentage < 0 &&
				percentage < -10 &&
				campaign.toBeCalculatedForROASAllogator
			) {
				betterPerformanceCampaigns.push(campaign)
			} else {
				campaign.allogatorSuggestion = Number(
					Number(fromMicrons(campaign.campaignBudget)).toFixed(2),
				)
				campaign.selectedForUpdate = false
				averageCampaigns.push(campaign)
			}
		} else if (campaign.ROAS && campaign.conversions) {
			const percentage = getIncreasePercentage(
				campaign.ROAS,
				Number(averageROAS),
			)
			if (
				percentage > 0 &&
				percentage > 10 &&
				campaign.toBeCalculatedForROASAllogator
			) {
				betterPerformanceCampaigns.push(campaign)
			} else if (
				percentage < 0 &&
				percentage < -10 &&
				campaign.toBeCalculatedForROASAllogator
			) {
				campaign.allogatorSuggestion = Number(
					(fromMicrons(campaign.campaignBudget) * 0.9).toFixed(2),
				)
				deductedBudget += Number(
					(fromMicrons(campaign.campaignBudget) * 0.1).toFixed(2),
				)
				worsePerformanceCampaigns.push(campaign)
			} else {
				campaign.allogatorSuggestion = Number(
					Number(fromMicrons(campaign.campaignBudget)).toFixed(2),
				)
				campaign.selectedForUpdate = false
				averageCampaigns.push(campaign)
			}
		} else if (!campaign.conversions) {
			campaign.allogatorSuggestion = Number(
				Number(Number(fromMicrons(campaign.campaignBudget)).toFixed(2)),
			)
			campaign.selectedForUpdate = false
			averageCampaigns.push(campaign)
		}
	})
	const betterPerformanceCampaignsWithAllogation =
		addROASSuggestionToBetterPerformingCampaign(
			betterPerformanceCampaigns,
			deductedBudget,
		)
	return {
		betterPerformanceCampaignsWithAllogation,
		worsePerformanceCampaigns,
		averageCampaigns,
	}
}

export const ultimateROASSuggestionGenerator = (
	campaigns: AdsGoogleCampaignItem[],
	adGroups: GoogleAdsGroupItem[],
): {
	sortedCampaigns: AdsGoogleCampaignItem[]
	mappedAdGroups: GoogleAdsGroupItem[]
} => {
	const mappedAdGroups: GoogleAdsGroupItem[] = []
	const filteredCampaigns = campaigns.filter(
		campaign => campaign.toBeCalculatedForROASAllogator === true,
	)
	const campaignAverageROAS = calculateAverageForROASSuggestion(
		filteredCampaigns,
		'ROAS',
	)
	const campaignAveragePricePerConversion = calculateAverageForROASSuggestion(
		filteredCampaigns,
		'pricePerConversion',
	)
	const {
		betterPerformanceCampaignsWithAllogation,
		worsePerformanceCampaigns,
		averageCampaigns,
	} = sortCampaignBasedOnPerformance(
		campaigns,
		Number(campaignAveragePricePerConversion),
		Number(campaignAverageROAS),
	)

	const mappedCampaigns: AdsGoogleCampaignItem[] = [].concat(
		betterPerformanceCampaignsWithAllogation,
		worsePerformanceCampaigns,
		averageCampaigns,
	)
	const sortedCampaigns = _.orderBy(mappedCampaigns, 'campaignName', 'asc')
	campaigns.map(campaign => {
		const filteredAdGroups = adGroups.filter(
			adGroup => adGroup.campaignName === campaign.campaignName,
		)
		const adGroupAverageROAS = calculateAverageForROASSuggestion(
			filteredAdGroups.filter(
				adGroup => adGroup.toBeCalculatedForROASAllogator === true,
			),
			'ROAS',
		)
		const adGroupAveragePricePerConversion = calculateAverageForROASSuggestion(
			filteredAdGroups.filter(
				adGroup => adGroup.toBeCalculatedForROASAllogator === true,
			),
			'pricePerConversion',
		)
		const adGroupWithSuggestion = filteredAdGroups.map(adGroup => {
			return addROASSuggestionToAdGroup(
				adGroup,
				Number(adGroupAveragePricePerConversion),
				Number(adGroupAverageROAS),
			)
		})
		mappedAdGroups.push(...adGroupWithSuggestion)
	})
	return { sortedCampaigns, mappedAdGroups }
}

export const formatToHtmlString = (content: string): string => {
	const countedLineBreaks = (content.match(/\r\n|\r|\n/g) || []).length
	let formattedString = content.slice()
	if (countedLineBreaks % 2 === 0) {
		for (let index = 1; index + 1 < countedLineBreaks; index++) {
			if (index === 1) {
				formattedString = formattedString.replace('\n\n', '<p>')
			} else {
				formattedString = formattedString.replace('\n\n', '</p><br><p>')
			}
		}
	}
	return formattedString
}

export const generateGraphDataOptions = (
	type: ESuggestionType | ETaskType,
	activeDataSource?: IDataSource,
	attributeType?: ETaskAttribute | null,
): { label: string; value: string[] }[] => {
	let option: any
	switch (type) {
		case ESuggestionType.seo:
			option = activeDataSource
				? Object.assign({}, SEO_GRAPH_VALUES, NON_DATASOURCE_SEO_GRAPH_VALUES)
				: NON_DATASOURCE_SEO_GRAPH_VALUES
			break
		case ESuggestionType.ads:
			option = GOOGLE_ADS_GRAPH_VALUES
			break
		case ESuggestionType.cro:
			option = CRO_GRAPH_VALUES
			break
		case ESuggestionType.some:
			option = FACEBOOK_GRAPH_VALUES
			break
		case ESuggestionType.custom:
			if (attributeType) {
				switch (attributeType) {
					case ETaskAttribute.ADS_account:
						option = GOOGLE_ADS_ACCOUNT_GRAPH_VALUES
						break
					case ETaskAttribute.ADS_ad:
						option = GOOGLE_ADS_AD_GRAPH_VALUES
						break
					case ETaskAttribute.ADS_adGroup:
						option = GOOGLE_ADS_GRAPH_VALUES
						break
					case ETaskAttribute.ADS_campaign:
						option = GOOGLE_ADS_CAMPAIGN_GRAPH_VALUES
						break
					case ETaskAttribute.SOME_account:
					case ETaskAttribute.SOME_ad:
					case ETaskAttribute.SOME_adSet:
					case ETaskAttribute.SOME_campaign:
						option = FACEBOOK_GRAPH_VALUES
						break
					case ETaskAttribute.CRO_exactPath:
					case ETaskAttribute.CRO_includePath:
						option = CRO_GRAPH_VALUES
						break
				}
			} else {
				option = [{ label: '', value: '' }]
			}
			break
		default:
			return [{ label: '', value: [''] }]
	}
	type KeyType = keyof typeof option
	return Object.keys(option).map(key => {
		return {
			value: option[key as KeyType].value,
			label: i18n.t(`values.${option[key as KeyType].label}`),
		}
	})
}
export const createDictionary = <T, P extends keyof T>(
	array: T[],
	key: P,
): Record<string, T> => {
	return array.reduce((all: Record<string, T>, curr: T): Record<string, T> => {
		if (curr[key] !== undefined) {
			// @ts-expect-error Testing
			all[curr[key]] = curr
		}
		return all
	}, {} as Record<string, T>)
}

export const getPropertyValue = (
	obj: Record<string, any>,
	property: string,
): string | any[] | Record<string, any> | null => {
	if (obj instanceof Object) {
		let foundValue = null
		Object.keys(obj).forEach(key => {
			if (key === property && obj[key]) {
				foundValue = obj[key]
			} else if (obj[key] instanceof Object) {
				const checkedValue = getPropertyValue(obj[key], property)
				if (checkedValue) {
					foundValue = checkedValue
				}
			}
		})
		return foundValue
	} else {
		return null
	}
}

export const getMaxKey = (
	lines: string[],
	totals: Record<string, number | string>,
): string => {
	return lines.reduce(
		(maxValues, line) => {
			if (
				typeof totals[line] === 'number' &&
				maxValues.value < Number(totals[line])
			) {
				maxValues.value = totals[line] as number
				maxValues.key = line
			}

			return maxValues
		},
		{ key: '', value: 0 },
	).key
}

/**
 *
 * @param paths Array of string paths
 * @param newChanges Object of changes that happened
 * @param existing Current object of
 * @returns Array of strings
 */
export const getAdsEventChangesWithMappedData =
	(
		mappedAdGroups: Record<string, GoogleAdsGroupItem>,
		mappedCampaigns: Record<string, AdsGoogleCampaignItem>,
	) =>
	(
		paths: string[],
		newChanges: Record<string, any>,
		existing: Record<string, any>,
	): string[] => {
		if (!paths.length) {
			return []
		}
		const excludedKeys: string[] = [
			'resource_name',
			'id',
			'final_url_suffix',
			'final_urls',
			'explicitly_shared',
			'delivery_method',
			'tracking_url_template',
			'match_type',
			'negative',
			'target_content_network',
			'path1',
			'path2',
			'advertising_channel_sub_type',
			'type',
			'campaign_budget',
		]

		const mappedPaths = paths.reduce((all: string[][], curr) => {
			if (excludedKeys.filter(filter => curr.includes(filter)).length === 0) {
				const joined = curr.split('.')
				all.push(joined)
			}

			return all
		}, [])

		const changeKey = Object.keys(newChanges)[0]
		return mappedPaths.reduce((all, curr) => {
			const foundExistingValue = getPropertyValue(
				existing[changeKey],
				curr[curr.length - 1],
			)
			const foundNewValue = getPropertyValue(
				newChanges[changeKey],
				curr[curr.length - 1],
			)
			if (curr[0] === 'target_cpa_micros') {
				all.push(
					`${i18n.t(`google change events.${curr[0]}`)} ${
						existing[changeKey][curr[0]]
							? `${i18n.t('common.from')} ${formatAmountsIntl(
									fromMicrons(existing[changeKey][curr[0]]),
							  )} €`
							: ''
					}  ${i18n.t('common.to')} ${formatAmountsIntl(
						fromMicrons(newChanges[changeKey][curr[0]]),
					)} €`,
				)
				return all
			}

			if (curr[0] === 'status') {
				if (
					newChanges[changeKey][curr[0]] &&
					newChanges[changeKey][curr[0]] === 'UNSPECIFIED'
				) {
					return all
				} else {
					all.push(
						`${i18n.t(`google change events.${changeKey}`)} ${i18n.t(
							'google change events.status',
						)} ${i18n.t('google change events.changed to')} ${i18n.t(
							`google change events.${newChanges[changeKey][curr[0]]}`,
						)}`,
					)
				}
			} else if (
				!foundNewValue &&
				paths.includes('keyword.text') &&
				curr[curr.length - 1] === 'text'
			) {
				all.push(
					`${i18n.t('google change events.removed')} ${i18n.t(
						`google change events.${curr[1]}`,
					)}: ${foundExistingValue}`,
				)
			} else if (
				foundNewValue &&
				paths.includes('keyword.text') &&
				curr[curr.length - 1] === 'text'
			) {
				all.push(
					`${i18n.t('google change events.added new')} ${i18n.t(
						`google change events.${curr[1]}`,
					)}: ${foundNewValue}`,
				)
			} else if (
				curr[curr.length - 1] === 'descriptions' ||
				(curr[curr.length - 1] === 'headlines' &&
					Array.isArray(foundExistingValue))
			) {
				const getTextDifferences = (
					existingValue: { text: string }[],
					newValue: { text: string }[],
				) => {
					return newValue.reduce((differences, cur) => {
						const foundDifference = Array.isArray(existingValue)
							? existingValue.find(val => val.text === cur.text)
							: null
						if (!foundDifference) {
							differences.push(cur.text)
						}
						return differences
					}, [] as string[])
				}
				const differences = getTextDifferences(
					// @ts-expect-error no worries
					foundExistingValue,
					foundNewValue,
				)
				if (!differences.length) {
					return all
				} else {
					all.push(
						`${i18n.t('google change events.added to')} ${i18n.t(
							`google change events.${curr[curr.length - 1]}`,
						)}: ${differences[0]}`,
					)
				}
			} else if (curr.length === 1) {
				if (newChanges[changeKey][curr[0]] === undefined) {
					if (curr[0] === 'ad_group' && changeKey === 'ad_group') {
						all.push(
							`${i18n.t('google change events.removed')} ${i18n.t(
								'google change events.ad_group',
							)}: ${mappedAdGroups[existing[changeKey][curr[0]]]?.adGroupName}`,
						)
					} else if (curr[0] === 'campaign' && changeKey === 'campaign') {
						all.push(
							`${i18n.t('google change events.removed')} ${i18n.t(
								'google change events.campaign',
							)}: ${
								mappedCampaigns[existing[changeKey][curr[0]]]?.campaignName
							}`,
						)
					}
				} else {
					if (curr.includes('amount_micros')) {
						all.push(
							`${i18n.t('google change events.campaign budget changed')} ${
								existing[changeKey][curr[0]]
									? `${i18n.t('common.from')} ${formatAmountsIntl(
											fromMicrons(existing[changeKey][curr[0]]),
									  )} €`
									: ''
							}  ${i18n.t('common.to')} ${formatAmountsIntl(
								fromMicrons(newChanges[changeKey][curr[0]]),
							)} €`,
						)
					} else {
						if (curr[0] === 'ad' && changeKey === 'ad_group_ad') {
							all.push(
								`${i18n.t('google change events.added new')} ${i18n.t(
									`google change events.${curr[0]}`,
								)}: ${newChanges[changeKey][curr[0]]?.name}`,
							)
						} else if (curr[0] === 'ad' && curr[0] === changeKey) {
							all.push(
								`${i18n.t('google change events.added new')} ${i18n.t(
									`google change events.${curr[0]}`,
								)}: ${newChanges[changeKey][curr[0]]?.name}`,
							)
						} else if (curr[0] === 'ad_group') {
							if (curr[0] === changeKey) {
								if (mappedAdGroups[newChanges[changeKey][curr[0]]]) {
									all.push(
										`${i18n.t('google change events.added new')} ${i18n.t(
											`google change events.${curr[0]}`,
										)}: ${
											mappedAdGroups[newChanges[changeKey][curr[0]]]
												?.adGroupName
										}`,
									)
								}
							}
						} else if (curr[0] === 'campaign') {
							return all
						} else if (curr[0] === 'text') {
							all.push(
								`${i18n.t(`google change events.${curr[0]}`)}: ${
									newChanges[changeKey][curr[0]]
								}`,
							)
						} else if (curr[0] === 'name') {
							all.push(`${newChanges[changeKey][curr[0]]}`)
						} else if (curr[0] === 'period') {
							all.push(
								`${i18n.t('google change events.period')} ${i18n.t(
									`google change events.${
										newChanges[changeKey][curr[0]] === 'DAILY'
											? 'DAILY'
											: 'UNSPECIFIED'
									}`,
								)}`,
							)
						} else {
							all.push(`${curr[0]}: ${newChanges[changeKey][curr[0]]}`)
						}
					}
				}
			} else if (curr.length === 2) {
				if (
					newChanges[changeKey][curr[0]] === undefined ||
					newChanges[changeKey][curr[0]][curr[1]] === undefined
				) {
					all.push(
						`${i18n.t('google change events.removed')}: ${curr[1]}: ${
							existing[changeKey][curr[0]][curr[1]]
						}`,
					)
				} else {
					if (Array.isArray(newChanges[changeKey][curr[0]][curr[1]])) {
						if (curr[0][1] === 'youtube_video_asset.youtube_video_id') {
							all.push(
								`${i18n.t(`google change events.${curr[0]}`)}  ${i18n.t(
									'google change events.changed',
								)}`,
							)
							return all
						}
						const values: string[] = []
						// @ts-expect-error We don't care about this
						newChanges[changeKey][curr[0]][curr[1]].forEach(value => {
							if (typeof value === 'object' && 'text' in value) {
								values.push(value.text)
							} else {
								values.push(value)
							}
						})
						all.push(`${curr[1]}: ${values.join(', ')}`)
					} else if (curr[1] === 'target_roas') {
						all.push(
							`${i18n.t(`google change events.${curr[1]}`)} ${i18n.t(
								'google change events.changed',
							)} ${
								existing[changeKey][curr[0]] &&
								existing[changeKey][curr[0]][curr[1]]
									? `${i18n.t('common.from')} ${formatAmountsIntl(
											existing[changeKey][curr[0]][curr[1]],
									  )}`
									: ''
							}  ${i18n.t('common.to')} ${formatAmountsIntl(
								newChanges[changeKey][curr[0]][curr[1]],
							)}`,
						)
						return all
					} else if (
						typeof newChanges[changeKey][curr[0]][curr[1]] === 'object'
					) {
						all.push(`${curr[1]}: ${newChanges[changeKey][curr[0]][curr[1]]}`)
					} else {
						all.push(`${curr[1]}: ${newChanges[changeKey][curr[0]][curr[1]]}`)
					}
				}
			} else if (curr.length === 3) {
				if (
					newChanges[changeKey][curr[0]] === undefined ||
					newChanges[changeKey][curr[0]][curr[1]][curr[2]] === undefined
				) {
					all.push(
						`${i18n.t('google change events.removed')}: ${curr[2]}: ${
							existing[changeKey][curr[0]][curr[1]][curr[2]]
						}`,
					)
				} else {
					all.push(
						`${curr[2]}: ${newChanges[changeKey][curr[0]][curr[1]][curr[2]]}`,
					)
				}
			} else if (curr.length === 4) {
				all.push(
					`${curr[3]}: ${
						newChanges[changeKey][curr[0]][curr[1]][curr[2]][curr[3]]
					}`,
				)
			}
			return all
		}, [])
	}

export const combineLines = (
	lines: string[] | MetricsKeysType[],
	data: Record<string, any>[],
): string[] => {
	const allLines = [...lines]
	if (Array.isArray(data) && data.length) {
		if (
			Object.keys(data[0]).some(key => {
				if (allLines.some(line => key === `${line}Compared`)) {
					return true
				}
				return false
			})
		) {
			lines.forEach(line => {
				allLines.push(`${line as string}Compared`)
			})
		}
	}
	return allLines as string[]
}

export const formatCustomReportMicronValue = (
	chartData: Record<string, any>[],
): any[] => {
	const formattedChartData = chartData.map((data: any) => {
		Object.keys(data).forEach(key => {
			if (microsKeys.includes(key.replace('Compared', ''))) {
				return (data[key] = data[key] / GoogleAdsScale)
			}
		})
		return data
	})
	return formattedChartData
}

export const formatFromPixels = (value?: string): string => {
	if (typeof value === 'string') {
		return value.replace('px', '')
	} else {
		return ''
	}
}

export const getMissingDataSources = (
	report: ReportTemplateCustom,
	dataSource: IDataSource,
): {
	[EDatasourceType.Ads]: boolean
	[EDatasourceType.Analytics]: boolean
	[EDatasourceType.AnalyticsGA4]: boolean
	[EDatasourceType.Facebook]: boolean
	[EDatasourceType.GSC]: boolean
	hasMissingDataSources: boolean
} => {
	const {
		FACEBOOK_PAGE,
		FACEBOOK_AD_MANAGER,
		GOOGLE_ADS,
		GOOGLE_ANALYTICS,
		GOOGLE_SEARCH,
		GOOGLE_ANALYTICS_GA4,
	} = checkDataSource(dataSource)
	const missingDataSources = report.items.reduce(
		(dataSources, page) => {
			page.items.forEach(component => {
				switch (component.dataSource) {
					case EDatasourceType.Ads:
						if (!GOOGLE_ADS) {
							dataSources.ads = true
							dataSources.hasMissingDataSources = true
						}
						break
					case EDatasourceType.Analytics:
						if (!GOOGLE_ANALYTICS) {
							dataSources.analytics = true
							dataSources.hasMissingDataSources = true
						}
						break
					case EDatasourceType.AnalyticsGA4:
						if (!GOOGLE_ANALYTICS_GA4) {
							dataSources.analyticsGA4 = true
							dataSources.hasMissingDataSources = true
						}
						break
					case EDatasourceType.Facebook:
						if (!FACEBOOK_AD_MANAGER) {
							dataSources.facebook = true
							dataSources.hasMissingDataSources = true
						}
						break
					case EDatasourceType.GSC:
						if (!GOOGLE_SEARCH) {
							dataSources.gsc = true
							dataSources.hasMissingDataSources = true
						}
						break

					default:
						break
				}
			})
			return dataSources
		},
		{
			[EDatasourceType.Ads]: false,
			[EDatasourceType.Analytics]: false,
			[EDatasourceType.AnalyticsGA4]: false,
			[EDatasourceType.Facebook]: false,
			[EDatasourceType.GSC]: false,
			hasMissingDataSources: false,
		},
	)
	return missingDataSources
}

export const filterAdsDataWithCampaigns = (
	selectedCampaigns: string[],
	adsData: Record<string, any>[],
): Record<string, any>[] => {
	const filteredData = selectedCampaigns.length
		? selectedCampaigns.reduce((all, curr) => {
				return all.concat(adsData.filter(item => item.campaignName === curr))
		  }, [] as Record<string, any>[])
		: adsData
	if (
		filteredData.length &&
		!filteredData[0].date &&
		!filteredData[0].matchType
	) {
		return filteredData
	}
	const combinedData = filteredData.reduce((all, curr) => {
		if (all[curr.date]) {
			Object.entries(curr).forEach(item => {
				const [key, value] = item
				if (key !== 'date') {
					if (typeof all[curr.date][key] === 'number') {
						all[curr.date][key] += value
					}
				}
			})
		} else if (all[curr.matchType] && 'matchType' in all[curr.matchType]) {
			Object.entries(curr).forEach(item => {
				const [key, value] = item
				if (key !== 'matchType') {
					if (typeof all[curr.matchType][key] === 'number') {
						all[curr.matchType][key] += value
					}
				}
			})
		} else if (typeof curr.matchType === 'number') {
			all[curr.matchType] = {}
			Object.entries(curr).forEach(item => {
				const [key, value] = item
				all[curr.matchType][key] = value
			})
		} else {
			all[curr.date] = {}
			Object.entries(curr).forEach(item => {
				const [key, value] = item
				if (key !== 'campaignName') {
					all[curr.date][key] = value
				}
			})
		}
		return all
	}, {})
	return Object.values(combinedData)
}

export const formatDataBySegment = (
	array: any[],
	fields: string[],
	segmenting: ECustomReportSegments,
): any[] => {
	// To set week to start from Monday
	moment.defineLocale('fi', {
		week: {
			dow: 1,
		},
	})
	let clonedData = _.cloneDeep(array)
	if (segmenting === 'week') {
		const weeklyData = array.reduce((combinedData, currentData) => {
			const week = moment(currentData.date).utc().subtract(2, 'hour').isoWeek()
			const weekAndYear =
				// Year might have 53 weeks which is messing with the formatting on the frontend
				week > 52
					? `${moment(currentData.date).subtract(2, 'hour').weekYear()}-W${52}`
					: `${moment(currentData.date).subtract(2, 'hour').weekYear()}-W${
							week < 10 ? `0${week}` : week
					  }`

			if (!combinedData[weekAndYear]) {
				combinedData[weekAndYear] = []
			}
			combinedData[weekAndYear].push(currentData)
			return combinedData
		}, {})
		const weeklyComparisonData = _.cloneDeep(array).reduce(
			(combinedData, currentData) => {
				if (Object.keys(currentData).some(key => key === 'dateCompared')) {
					const comparisonWeek = moment(currentData.dateCompared)
						.utc()
						.subtract(2, 'hour')
						.isoWeek()
					const comparisonWeekAndYear =
						// Year might have 53 weeks which is messing with the formatting on the frontend
						comparisonWeek > 52
							? `${moment(currentData.dateCompared)
									.subtract(2, 'hour')
									.weekYear()}-W${52}`
							: `${moment(currentData.dateCompared)
									.subtract(2, 'hour')
									.weekYear()}-W${
									comparisonWeek < 10 ? `0${comparisonWeek}` : comparisonWeek
							  }`
					currentData.dateCompared = comparisonWeekAndYear
					if (!combinedData[comparisonWeekAndYear]) {
						combinedData[comparisonWeekAndYear] = []
					}
					combinedData[comparisonWeekAndYear].push(currentData)
				}

				return combinedData
			},
			{},
		)
		const currentData = Object.keys(weeklyData).map(key => {
			return weeklyData[key].reduce(
				(
					total: Record<string | number, any>,
					value: Record<string, any>,
					index: number,
					arr: Record<string, any>[],
				) => {
					if (!total.date) {
						total.date = key
						fields.forEach(field => {
							total[field] = parseFloat(value[field])
						})
					} else {
						fields.forEach(field => {
							total[field] += parseFloat(value[field])
						})
					}

					if (index + 1 === arr.length) {
						fields.forEach(field => {
							if (field === 'ctr') {
								total.ctr = formatDecimals((total.ctr / arr.length) * 100, 2)
							} else {
								if (field === 'position') {
									total[field] = formatDecimals(total[field] / arr.length, 2)
								}
							}
						})
					}
					return total
				},
				{},
			)
		})
		const comparisonData = Object.keys(weeklyComparisonData).map(key => {
			return weeklyComparisonData[key].reduce(
				(
					total: Record<string | number, any>,
					value: Record<string, any>,
					index: number,
					arr: Record<string, any>[],
				) => {
					if (!total.dateCompared) {
						total.dateCompared = key
						fields.forEach(field => {
							if (value[`${field}Compared`] != null) {
								total[`${field}Compared`] = parseFloat(
									value[`${field}Compared`],
								)
							}
						})
						if (value.comparisonDateRange) {
							total.comparisonDateRange = value.comparisonDateRange
						}
					} else {
						fields.forEach(field => {
							if (value[`${field}Compared`] != null) {
								total[`${field}Compared`] += parseFloat(
									value[`${field}Compared`],
								)
							}
						})
					}

					if (index + 1 === arr.length) {
						fields.forEach(field => {
							if (field === 'ctr') {
								if (total[`${field}Compared`]) {
									total.total[`${field}Compared`] = formatDecimals(
										(total.total[`${field}Compared`] / arr.length) * 100,
										2,
									)
								}
							} else {
								if (field === 'position') {
									if (total[`${field}Compared`]) {
										total.total[`${field}Compared`] = formatDecimals(
											total.total[`${field}Compared`] / arr.length,
											2,
										)
									}
								}
							}
						})
					}
					return total
				},
				{},
			)
		})
		clonedData = currentData.map((data, index) => {
			const comparedData = comparisonData[index] ? comparisonData[index] : {}
			return { ...data, ...comparedData }
		})
	} else if (segmenting === 'month') {
		const monthlyData = array.reduce((combinedData, currentData) => {
			const month = `${moment(currentData.date)
				.utc()
				.subtract(2, 'hour')
				.year()}-${
				moment(currentData.date).utc().subtract(2, 'hour').month() + 1
			}`
			if (!combinedData[month]) {
				combinedData[month] = []
			}
			combinedData[month].push(currentData)
			return combinedData
		}, {})
		const monthlyComparisonData = _.cloneDeep(array).reduce(
			(combinedData, currentData) => {
				if (Object.keys(currentData).some(key => key === 'dateCompared')) {
					const comparisonMonth = `${moment(currentData.dateCompared)
						.utc()
						.subtract(2, 'hour')
						.year()}-${
						moment(currentData.dateCompared).utc().subtract(2, 'hour').month() +
						1
					}`

					currentData.dateCompared = comparisonMonth
					if (!combinedData[comparisonMonth]) {
						combinedData[comparisonMonth] = []
					}
					combinedData[comparisonMonth].push(currentData)
				}

				return combinedData
			},
			{},
		)
		const currentData = Object.keys(monthlyData).map(key => {
			return monthlyData[key].reduce(
				(
					total: Record<string | number, any>,
					value: Record<string, any>,
					index: number,
					arr: Record<string, any>[],
				) => {
					if (!total.date) {
						total.date = key
						fields.forEach(field => {
							total[field] = parseFloat(value[field])
						})
					} else {
						fields.forEach(field => {
							total[field] += parseFloat(value[field])
						})
					}

					if (index + 1 === arr.length) {
						fields.forEach(field => {
							if (field === 'ctr') {
								total.ctr = formatDecimals((total.ctr / arr.length) * 100, 2)
							} else {
								if (field === 'position') {
									total[field] = formatDecimals(total[field] / arr.length, 2)
								}
							}
						})
					}
					return total
				},
				{},
			)
		})
		const comparisonData = Object.keys(monthlyComparisonData).map(key => {
			return monthlyComparisonData[key].reduce(
				(
					total: Record<string | number, any>,
					value: Record<string, any>,
					index: number,
					arr: Record<string, any>[],
				) => {
					if (!total.dateCompared) {
						total.dateCompared = key
						fields.forEach(field => {
							if (value[`${field}Compared`] != null) {
								total[`${field}Compared`] = parseFloat(
									value[`${field}Compared`],
								)
							}
						})
						if (value.comparisonDateRange) {
							total.comparisonDateRange = value.comparisonDateRange
						}
					} else {
						fields.forEach(field => {
							if (value[`${field}Compared`] != null) {
								total[`${field}Compared`] += parseFloat(
									value[`${field}Compared`],
								)
							}
						})
					}

					if (index + 1 === arr.length) {
						fields.forEach(field => {
							if (field === 'ctr') {
								if (total[`${field}Compared`]) {
									total.total[`${field}Compared`] = formatDecimals(
										(total.total[`${field}Compared`] / arr.length) * 100,
										2,
									)
								}
							} else {
								if (field === 'position') {
									if (total[`${field}Compared`]) {
										total.total[`${field}Compared`] = formatDecimals(
											total.total[`${field}Compared`] / arr.length,
											2,
										)
									}
								}
							}
						})
					}
					return total
				},
				{},
			)
		})
		clonedData = currentData.map((data, index) => {
			const comparedData = comparisonData[index] ? comparisonData[index] : {}
			return { ...data, ...comparedData }
		})
	}
	return clonedData.sort((a, b) => moment(a.date).diff(moment(b.date)))
}

export const formatValuesByKeyForCustomReport = (
	row: Record<string, any>,
	key: string,
): {
	value: string
	comparisonValue?: string
	hasComparisonValue?: boolean
} => {
	const percetangeValues = ['ctr', 'bounceRate']
	const multiplyValues = ['ctr']
	const hasComparisonValue = Object.keys(row).some(
		property => property === `${key}Compared`,
	)
	if (percetangeValues.includes(key)) {
		const hasMultiplyValue = multiplyValues.includes(key)

		const value = hasMultiplyValue ? row[key] * 100 : row[key]
		return hasComparisonValue
			? {
					value: `${formatDecimals(value, 2).toFixed(2)} % `,
					comparisonValue: `(${formatDecimals(
						hasMultiplyValue
							? row[`${key}Compared`] * 100
							: row[`${key}Compared`] + 0,
						2,
					).toFixed(2)} %)`,
					hasComparisonValue,
			  }
			: { value: `${formatDecimals(value, 2).toFixed(2)} %` }
	}

	if (
		key.includes('average_cost') ||
		key.includes('cost_per_conversion') ||
		key.includes('cost_micros')
	) {
		return hasComparisonValue
			? {
					value: `${formatDecimals(row[key], 2)}  `,
					comparisonValue: `(${formatDecimals(row[`${key}Compared`], 2)})`,
					hasComparisonValue,
			  }
			: { value: `${formatDecimals(row[key], 2)} ` }
	} else {
		return isNaN(Number(row[key]))
			? { value: row[key] }
			: hasComparisonValue
			? {
					value: `${formatAmountsIntl(formatDecimals(row[key], 2))} `,
					comparisonValue: ` (${formatAmountsIntl(
						formatDecimals(row[`${key}Compared`], 2),
					)})`,
					hasComparisonValue,
			  }
			: { value: `${formatAmountsIntl(formatDecimals(row[key], 2))} ` }
	}
}

export const markdownToHtml = (markdownText: string): string => {
	const processedFile = remark()
		.use(remarkGfm)
		.use(remarkHtml)
		.processSync(markdownText)

	let htmlString = String(processedFile)

	htmlString = htmlString.replace(/<p><br><\/p>/g, '')

	htmlString = htmlString
		.replace(/<p>/g, '<br><p>')
		.replace(/<h2>/g, '<br><h2>')
		.replace(/<\/h1>/g, '</h1><br>')
		.replace(/<h3>/g, '<br><h3>')

	htmlString = htmlString.replace(/<p><br><\/p>/g, '')

	return htmlString
}

export const isHTML = (string: string): boolean => {
	return Array.from(
		new DOMParser().parseFromString(string, 'text/html').body.childNodes,
	).some(({ nodeType }) => nodeType == 1)
}

export const calculateTotalsForShopifyFeed = (
	products: IShopifyProductWithConversion[],
	title: string,
): any => {
	const totals = {
		availability: 'total',
		brand: '',
		condition: '',
		googleProductCategory: '',
		productType: '',
		description: '',
		title: title,
		link: '',
		imageLink: '',
		mpn: '',
		labels: [],
		additionalImageLinks: [],
		clicks: 0,
		conversionsValue: 0,
		cost: 0,
		conversions: 0,
		allConversions: 0,
		impressions: 0,
		costPerConversion: 0,
		orders: 0,
		revenue: 0,
		ROAS: 0,
		POAS: 0,
		CTR: 0,
		price: null,
	}

	products.forEach(product => {
		totals.clicks += product.clicks || 0
		totals.conversionsValue += product.conversionsValue || 0
		totals.cost += product.cost || 0
		totals.conversions += product.conversions || 0
		totals.allConversions += product.allConversions || 0
		totals.impressions += product.impressions || 0
		totals.costPerConversion += product.costPerConversion || 0
		totals.orders += product.orders || 0
		totals.revenue += product.revenue || 0
		totals.ROAS += product.ROAS || 0
		totals.POAS += product.POAS || 0
		totals.CTR += product.CTR || 0
	})
	return totals
}
